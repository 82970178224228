<script setup>
import { ref, reactive, onMounted, inject } from 'vue';

const { checkPermission, checkSubscription } = inject('appUtil');

const show = ref(true);

let hrnflexPages = reactive([
    {
        href: '/hrDashboard',
        img: 'app-side-1',
        text: 'Dashboard',
    },
    {
        children: true,
        img: 'app-side-5',
        text: 'Team',
        noPermission: !checkPermission('TEAM_VIEW') && !checkPermission('TEAM_EDIT') && !checkPermission('TEAM_SETTING_VIEW') && !checkPermission('TEAM_SETTING_EDIT'),
    },
    {
        parent: 'app-side-5',
        show: false,
        href: checkPermission('TEAM_VIEW') || checkPermission('TEAM_EDIT') ? '/employees/employees' : '/employees/invited_employees',
        href_sub: ['/employees/invited_employees'],
        text: 'Employees',
        noPermission: !checkPermission('TEAM_VIEW') && !checkPermission('TEAM_EDIT') && !checkPermission('TEAM_SETTING_VIEW') && !checkPermission('TEAM_SETTING_EDIT'),
    },
    {
        parent: 'app-side-5',
        show: false,
        href: '/organization/department',
        href_sub: ['/organization/job_title'],
        text: 'Organization',
        noPermission: !checkPermission('TEAM_SETTING_VIEW') && !checkPermission('TEAM_SETTING_EDIT'),
    },
    {
        children: true,
        img: 'app-side-2',
        text: 'Attendance',
        noPermission: !checkPermission('ATTENDANCE_VIEW') && !checkPermission('ATTENDANCE_EDIT'),
    },
    {
        parent: 'app-side-2',
        show: false,
        href: '/time/time_reports',
        text: 'Time Log Report',
        noPermission: !checkPermission('ATTENDANCE_VIEW') && !checkPermission('ATTENDANCE_EDIT'),
    },
    {
        parent: 'app-side-2',
        show: false,
        href: '/time/weekly_monthly',
        text: 'Weekly/Monthly Report',
        noPermission: !checkPermission('ATTENDANCE_VIEW') && !checkPermission('ATTENDANCE_EDIT'),
    },
    {
        parent: 'app-side-2',
        show: false,
        href: '/time/work_policy',
        text: 'Work Schedule Settings',
        noPermission: !checkPermission('ATTENDANCE_POLICY_VIEW') && !checkPermission('ATTENDANCE_POLICY_EDIT'),
    },
    {
        children: true,
        img: 'app-side-3',
        text: 'Leaves',
        noPermission: !checkPermission('LEAVE_VIEW') && !checkPermission('LEAVE_EDIT'),
    },
    {
        parent: 'app-side-3',
        show: false,
        href: '/leave/leave_usage_history',
        text: 'Leave Usage History',
        noPermission: !checkPermission('LEAVE_VIEW') && !checkPermission('LEAVE_EDIT'),
    },
    {
        parent: 'app-side-3',
        show: false,
        href: '/leave/grant_management',
        text: 'Manual Grant',
        noPermission: !checkPermission('LEAVE_VIEW') && !checkPermission('LEAVE_EDIT'),
    },
    {
        parent: 'app-side-3',
        show: false,
        href: '/leave/leave_settings',
        text: 'Leave Settings',
        noPermission: !checkPermission('LEAVE_SETTING_VIEW') && !checkPermission('LEAVE_SETTING_EDIT'),
    },
    {
        children: true,
        img: 'app-side-11',
        text: 'Filing',
        noPermission: !checkPermission('FILING_EDIT'),
    },
    {
        parent: 'app-side-11',
        show: false,
        href: '/filing/approval_management',
        text: 'Approval Management',
        noPermission: !checkPermission('FILING_APPROVAL_EDIT'),
    },
    {
        parent: 'app-side-11',
        show: false,
        href: '/filing/filing_settings',
        text: 'Filing Settings',
        noPermission: !checkPermission('FILING_EDIT'),
    },
    {
        children: true,
        img: 'flexben_icon',
        text: 'FlexBen',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/summary',
        text: 'Summary',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/topup',
        text: 'Top-up',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/transfer',
        href_sub: ['/flex_ben/deduct'],
        text: 'Transfer/Deduct',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/mileage_history/point_balance',
        href_sub: ['/flex_ben/mileage_history/transfer_history','/flex_ben/mileage_history/deduct_history'],
        text: 'Point History',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/usage_history',
        text: 'Usage History',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/flex_ben_setting',
        href_sub: ['/flex_ben/flex_ben_setting/add_flex_ben_type', '/flex_ben/flex_ben_setting/edit_flex_ben_type'],
        text: 'FlexBen Settings',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        children: true,
        img: 'app-side-12',
        text: 'Payroll',
        noPermission: !checkPermission('PAYROLL_SETTING_EDIT') || !checkSubscription('PAYROLL_USAGE'),
    },
    {
        parent: 'app-side-12',
        show: false,
        href: '/payroll/payroll_processing',
        text: 'Payroll Processing',
        noPermission: !checkPermission('PAYROLL_SETTING_EDIT') || !checkSubscription('PAYROLL_USAGE'),
    },
    {
        parent: 'app-side-12',
        show: false,
        href: '/payroll/payroll-report',
        text: 'Report',
        noPermission: !checkPermission('PAYROLL_SETTING_EDIT'),
    },
    {
        parent: 'app-side-12',
        show: false,
        href: '/payroll/payroll-settings',
        text: 'Settings',
        noPermission: !checkPermission('PAYROLL_SETTING_EDIT') || !checkSubscription('PAYROLL_USAGE'),
    },
    {
        parent: 'app-side-12',
        show: false,
        href: '/payroll/payroll-rates-and-taxes',
        text: 'Rates & Taxes',
        noPermission: !checkPermission('PAYROLL_SETTING_EDIT') || !checkSubscription('PAYROLL_USAGE'),
    },
    {
        children: true,
        img: 'app-side-7',
        text: 'Settings',
        noPermission: !checkPermission('SETTING_EDIT'),
    },
    {
        parent: 'app-side-7',
        show: false,
        href: '/settings/company-settings',
        text: 'Company Settings',
        noPermission: !checkPermission('SETTING_EDIT'),
    },
    {
        parent: 'app-side-7',
        show: false,
        href: '/settings/admin_settings',
        text: 'Admin Settings',
        noPermission: !checkPermission('SETTING_EDIT'),
    },
]);

const flexbenOnlyPages = reactive([
    {
        href: '/flex_ben/dashboard',
        img: 'app-side-1',
        text: 'Dashboard',
    },
    {
        children: true,
        img: 'app-side-5',
        text: 'Team',
        noPermission: !checkPermission('TEAM_VIEW') && !checkPermission('TEAM_EDIT') && !checkPermission('TEAM_SETTING_VIEW') && !checkPermission('TEAM_SETTING_EDIT'),
    },
    {
        parent: 'app-side-5',
        show: false,
        href: checkPermission('TEAM_VIEW') || checkPermission('TEAM_EDIT') ? '/employees/employees' : '/employees/invited_employees',
        href_sub: ['/employees/invited_employees'],
        text: 'Employees',
        noPermission: !checkPermission('TEAM_VIEW') && !checkPermission('TEAM_EDIT') && !checkPermission('TEAM_SETTING_VIEW') && !checkPermission('TEAM_SETTING_EDIT'),
    },
    {
        parent: 'app-side-5',
        show: false,
        href: '/organization/department',
        href_sub: ['/organization/job_title'],
        text: 'Organization',
        noPermission: !checkPermission('TEAM_SETTING_VIEW') && !checkPermission('TEAM_SETTING_EDIT'),
    },
    {
        children: true,
        img: 'flexben_icon',
        text: 'FlexBen',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/topup',
        text: 'Top-up',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/transfer',
        href_sub: ['/flex_ben/deduct'],
        text: 'Transfer/Deduct',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/mileage_history/point_balance',
        href_sub: ['/flex_ben/mileage_history/transfer_history','/flex_ben/mileage_history/deduct_history'],
        text: 'Point History',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        parent: 'flexben_icon',
        show: false,
        href: '/flex_ben/flex_ben_setting',
        href_sub: ['/flex_ben/flex_ben_setting/add_flex_ben_type', '/flex_ben/flex_ben_setting/edit_flex_ben_type'],
        text: 'FlexBen Settings',
        noPermission: !checkPermission('FLEXBEN_EDIT'),
    },
    {
        children: true,
        img: 'app-side-7',
        text: 'Settings',
        noPermission: !checkPermission('SETTING_EDIT'),
    },
    {
        parent: 'app-side-7',
        show: false,
        href: '/settings/company-settings',
        text: 'Company Settings',
        noPermission: !checkPermission('SETTING_EDIT'),
    },
    {
        parent: 'app-side-7',
        show: false,
        href: '/settings/admin_settings',
        text: 'Admin Settings',
        noPermission: !checkPermission('SETTING_EDIT'),
    },
]);

// side menu 분리
let items = checkSubscription('FLEXBEN_ONLY_USAGE') ? flexbenOnlyPages : hrnflexPages;

onMounted(() => {
    const splitPathPure = window.location.pathname;

    const page = `${splitPathPure}`;
    let parent = '';

    items.forEach((item, index) => {
        var isEq = item['href'] && (item['href'] === page || item['href'] + '/' === page);
        if (typeof item['href_sub'] === 'object' && item['href_sub'].length) {
            const find1 = item['href_sub'].find((element) => {
                return element === page || element + '/' === page;
            });
            if (find1) isEq = true;
        }

        if (isEq) {
            items[index]['show'] = true;
            items[index]['isCurrentHref'] = true;
            parent = item['parent'];
        }
    });
    items.forEach((item, index) => {
        if (item['parent'] === parent) {
            items[index].show = true;
        }
    });
});

function clickIcon(clickedItem) {
    if (!clickedItem.children) {
        location.href = clickedItem.href;
        return;
    }

    const isMenuFold = !show.value;
    const parent = clickedItem.img;
    items.forEach((item, index) => {
        if (item['parent'] === parent) {
            const show = items[index]['show'];
            items[index].show = isMenuFold ? true : !show;
        }
    });

    if (isMenuFold) {
        show.value = true;
    }
}

function toggleMenu() {
    show.value = !show.value;
}

function isActiveParentMenu(clickedItem) {
    if (clickedItem.parent) {
        return false;
    }

    if (clickedItem.isCurrentHref) {
        return true;
    }

    const img = clickedItem.img;
    const hasActiveChild = items.some((item) => item.parent === img && item.isCurrentHref);
    return hasActiveChild;
}
</script>

<template>
    <div id="app-aside" class="p-2.5 w-64 blu transition-all duration-500 z-[70] h-screen overflow-y-auto overflow-x-hidden sticky top-0 self-start" :class="{ 'p-0': !show, 'w-12': !show }">
        <div class="h-20 flex justify-center items-center gap-2">
            <img v-if="show" src="@/assets/img/hrnflex_wh_logo.png" alt="white_logo" class="h-5" />
            <h1 class="text-2xl font-bold text-white whitespace-nowrap"></h1>
        </div>
        <div class="mt-5">
            <!-- 메뉴 열림 (unfold) -->
            <template v-if="show">
                <template v-for="(item, index) in items" v-bind:key="index">
                    <a
                        v-if="!item.noPermission"
                        v-show="!item.parent || item.show"
                        class="flex h-12 rounded-md items-center blu-hover transition-all duration-500 cursor-pointer"
                        :class="{ 'mt-4': index > 0, 'blu-dark': item['isCurrentHref'] }"
                        @click="clickIcon(item)">
                        <img v-if="item.img" class="ml-4" :src="require(`@/assets/img/${item.img}.svg`)" :alt="item.img" width="18" />
                        <span class="flex-1 ml-4 text-base font-semibold text-white whitespace-nowrap" :class="{ 'ml-12': !item.img }">{{ item.text }}</span>
                        <img v-show="item.children && items.find((item2) => item2.parent === item.img && item2.show)" class="mr-5" :src="require(`@/assets/img/app-side-8.svg`)" alt="app-side-8" />
                        <img v-if="item.children && items.find((item2) => item2.parent === item.img && !item2.show)" class="mr-5" :src="require(`@/assets/img/app-side-10.svg`)" alt="app-side-10" />
                    </a>
                </template>
            </template>

            <!--메뉴 닫힘 (fold)-->
            <template v-if="!show">
                <template v-for="(item, index) in items" v-bind:key="index">
                    <a
                        v-if="!item.noPermission"
                        v-show="!item.parent"
                        class="h-12 rounded-md flex items-center blu-hover transition-all duration-500 cursor-pointer"
                        :class="{ 'mt-4': index > 0, 'blu-dark': isActiveParentMenu(item) }"
                        @click="clickIcon(item)">
                        <img v-if="item.img" class="ml-4" :src="require(`@/assets/img/${item.img}.svg`)" :alt="item.img" width="18" />
                        <span class="flex-1 ml-4 text-base font-semibold text-white whitespace-nowrap" :class="{ 'ml-12': !item.img }"></span>
                    </a>
                </template>
            </template>
        </div>
    </div>
    <Teleport to="body">
        <a
            class="fixed top-8 flex justify-center items-center transition-all duration-500 cursor-pointer blu-hover z-[70] rounded-full p-1"
            :class="[show ? 'translate-x-60' : 'translate-x-8']"
            href="javascript:void(0)"
            @click="toggleMenu">
            <img class="menu-button" :class="{ 'rotate-180': !show }" src="@/assets/img/app-side-9.svg" alt="app-side-9" />
        </a>
    </Teleport>
</template>
<style scoped>
.blu {
    background: #4361ee;
}
.blu-dark {
    background: #334cba;
}
.blu-hover:hover {
    background: #334cba;
}
.blu-text {
    color: #4361ee;
}
.menu-button {
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
</style>
