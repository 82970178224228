<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/solid';

export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
    },
    props: {
        modelValue: [String, Object, Number],
        name: String,
        error: String,
        readonly: Boolean,
        options: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [{ text: null, value: null, explanation: null }],
            minSize: {
                type: String,
                default: null,
            },
        },
        placeholder: {
            type: String,
            default: 'Select an Option',
        },
        useDefaultMsg: {
            type: Boolean,
            default: false,
        },
        minWFit: Boolean,
        full: Boolean,
        width60: Boolean,
        width40: Boolean,
        width24: Boolean,
        width20: Boolean,
        hFull: Boolean,
        height10: Boolean,
        height11: Boolean,
        height12: Boolean,
        defaultRed: Boolean,
        placeholderTextSmall : {
            type: Boolean,
            default: false,
        },
        titleBold : Boolean,
        isRequired : Boolean,
        errorMtCustom : String
    },
    emits: ['change', 'update:modelValue'],
    data() {
        return {
            showOptions: false,
            selectText: null,
            explanation: null,
        };
    },
    computed: {
        selectOption() {
            const self = this;
            const option = [...self.options];
            if (self.useDefaultMsg) {
                option.unshift({ text: self.placeholder, value: null });
            }
            return option;
        },
        selected: {
            get() {
                const self = this;
                if (!self.modelValue && self.useDefaultMsg) {
                    return self.selectOption[0];
                }

                // modelValue 가 object {} 인 경우 include로 찾을 수 없음
                const isInclude = self.valueArray.some((i) => JSON.stringify(i) == JSON.stringify(self.modelValue));
                if (self.modelValue && !isInclude) {
                    self.$emit('update:modelValue', self.valueArray[0]);
                }

                return self.selectOption.find((item) => JSON.stringify(item.value) == JSON.stringify(self.modelValue));
            },
            set(value) {
                const self = this;
                self.$emit('update:modelValue', value);
                self.$emit('change');
            },
        },
        valueArray() {
            const self = this;
            return self.options.filter((option) => option.value != null).map((option) => option.value);
        },
    },
    methods: {
        updateExplantion(value) {
            if (!value) return;
            const self = this;
            const option = self.selectOption.find((e) => e.value == value);
            self.explanation = option.explanation;
        },
    },
    mounted() {},
};
</script>

<template>
    <div class="relative">
        <Listbox as="div" v-model="selected" :disabled="readonly">
            <ListboxLabel class="text-sm" v-show="name" :class="[titleBold ? 'font-semibold text-slate-800': 'font-light text-zinc-500']"> {{ name }} 
                <span class="text-red-600" v-show="isRequired && !readonly">*</span>
            </ListboxLabel>
            <div class="relative" @click="click">
                <ListboxButton
                    class="bg-white relative border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default max-w-6xl overflow-auto focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    :class="{
                        'w-96': !full && !width60 && !width40 && !width20 && !width24,
                        'w-full': full,
                        'w-60': width60,
                        'w-40': width40,
                        'w-24': width24,
                        'w-20': width20,
                        'bg-gray-100': readonly,
                        'h-full': hFull,
                        'h-10': height10,
                        'h-11': height11,
                        'h-12': height12,
                    }">
                    <span class="block truncate text-base font-light" :class="{ 'text-gray-400': !modelValue, 'text-red-600': !modelValue && defaultRed , 'sm:text-sm' : placeholderTextSmall }">
                        {{ selected ? selected.text : placeholder }}
                    </span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <img src="@/assets/img/elements-select-1.svg" alt="elements-select-1" v-show="!readonly" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-w-6xl bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        :class="{
                            'w-96': !full && !width60 && !width40 && !width20 && !width24,
                            'w-full': full,
                            'w-60': width60,
                            'w-40': width40,
                            'w-20': width20,
                            'w-24': width24,
                        }">
                        <ListboxOption as="template" v-for="item in selectOption" :key="item.value" :value="item.value" v-slot="{ active, selected }">
                            <li :class="[active ? 'text-sm text-white blu' : 'text-sm font-light text-zinc-500', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                <span :class="{ 'font-semibold': selected, 'font-normal': !selected, 'min-w-fit': minWFit }" class="block truncate">
                                    {{ item.text }}
                                </span>
                                <span v-if="selected" :class="[active ? 'text-white' : 'blu-text', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        <p class="mt-3.5 text-xs font-normal text-grey-500" v-if="explanation">*{{ explanation }}</p>
        <p class=" text-xs font-normal text-[#FF0000]" :class="[ !!errorMtCustom ? errorMtCustom : 'mt-3.5'  ]" v-show="error">*{{ error }}</p>
    </div>
</template>
<style scoped>
.blu {
    background: #4361ee;
}
.blu:hover {
    background: #4361ee;
}
.blu-text {
    color: #4361ee;
}
.blu:focus {
    outline: 1px solid #4361ee;
}
.blu-border {
    border: 1px solid #4361ee;
}
</style>
