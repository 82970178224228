import '@babel/polyfill'; // with "@babel/preset-env"
import { createAxios }from '@/plugins/app-axios';
import { serverApi } from '@/plugins/vue-api-server';
import emitter from '@/plugins/event-hub';
import moment from 'moment-timezone';
import {DateFormatUtil, ValidateUtil} from "@/plugins/app-util";

async function fetchAuthorityFromNetwork(){
    const pageId = document.querySelector('meta[name="pageId"]').content;

    const url = new URL(serverApi('uri', 'get-sign-on') ) ;
    const params = new URLSearchParams();
    params.append('pageId', pageId);
    url.search = params.toString();

    const urlInit = {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        mode: 'cors',
        // credentials: process.env.VUE_APP_SERVER_MODE !== 'loc'?'include':'omit',
        credentials: 'include',
        cache: 'no-store'
    };
    try {
        let response = await fetch(url, urlInit);
        if (response.ok) { // HTTP 상태 코드가 200~299일 경우
            // 응답 몬문을 받습니다(관련 메서드는 아래에서 설명).
            let js = await response.json();
            return js;
        } else {
            console.error("[AUTH] fetchLogOnProfileFromNetwork HTTP-Error: ", response.status);
            location.href = "/login"
            return;
        }
    } catch (error) {
        console.error(error);
        location.href = "/login"
        return;
    }
}


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = '';

    try{
        if(document.cookie) decodedCookie = decodeURIComponent(document.cookie);
    }catch{}

    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Only sessionCookie
function setCookieSession(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";path=/";
}

async function initApp(app, disableSignOn = false ){
    var isProd = process.env.NODE_ENV === 'production';
    // for debugging purpose
    if( isProd ){
        window.slog = function(){};
        window.slog.clear = function(){};
    }else{
        window.slog = console.log.bind(console);
        window.slog.clear = console.clear.bind(console);
    }

    moment.tz.setDefault("Asia/Manila");
    window.logOnProfile = null;

    if( ! disableSignOn ){
        let authResponse = await fetchAuthorityFromNetwork();
        window.logOnProfile = authResponse;
        // 여기에서 page permission을 확인하면 됨
        const hasPermission =  checkPagePermission();
        if( ! hasPermission ){
            alert("You don't have permission this page");
            history.back();
            return;
        }
        checkMaintenance(window.logOnProfile.data.maintenance)
    }

    // app.config.unwrapInjectedRef = true;
    app.config.globalProperties.$axios=createAxios(emitter);
    app.config.globalProperties.$api=serverApi;
    app.config.globalProperties.$emitter=emitter;
    app.config.globalProperties.$isLogOn=function(){
        let logOnProfile = window.logOnProfile;
        return !!logOnProfile;
    }
    app.config.globalProperties.$appUtil={
        uuidv4, checkPermission, checkSubscription
    };

    app.provide('api', serverApi);
    app.provide('emitter', emitter);
    app.provide('axios', app.config.globalProperties.$axios);
    app.provide('appUtil', app.config.globalProperties.$appUtil);

    app.config.globalProperties.$validateUtil=ValidateUtil;
    app.config.globalProperties.$dateFormatUtil=DateFormatUtil;

    sentryInitApp(app);
    postHogInitApp(app);

    return app;
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
    });
}

function checkSubscription(subscriptionCode){
    if( ! window.logOnProfile ) return false;

    const { subscriptions } = window.logOnProfile.data;
    // console.log('permissions ? : ', permissions);
    // console.log('permissions.includes(pageCode) ? : ', permissions.includes(pageCode), pageCode);
    return subscriptions.includes(subscriptionCode);
}

function checkPermission(pageCode){
    if( ! window.logOnProfile ) return false;

    const { permissions } = window.logOnProfile.data;
    return permissions.includes(pageCode);
}

function checkPagePermission(){
    const pageCodeList = document.querySelector('meta[name="permission"]').content;
    if( !pageCodeList ) return true;

    for (const pageCode of pageCodeList.split(',')) {
        if( checkPermission(pageCode) ) {
            return true;
        }
    }

    return false;
}

function checkMaintenance( maintenance ){
    if( ! maintenance ) return;
    const { systemMaintenanceSeq, maintenanceUseYn } = maintenance;
    if( maintenanceUseYn == "Y" ){
        location.href = `/system_maintenance?maintenanceSeq=${systemMaintenanceSeq}`;
        return;
    }
}

function sentryInitApp(app){
    const tracesRate = (()=>{
        switch(process.env.VUE_APP_SERVER_MODE){
            case "dev" : return 1.00;   // 100 %
            case "loc" : return 1.00;
            case "stg" : return 1.00;
            case "prd" : return 0.01;   // 1 %
            default : return 0.01;
        }
    })();

    const errorRate = (()=>{
        switch(process.env.VUE_APP_SERVER_MODE){
            case "dev" : return 1.00;   // 100 %
            case "loc" : return 1.00;
            case "stg" : return 1.00;
            case "prd" : return 0.10;   // 10 %
            default : return 0.01;
        }
    })();

    const enableSentry = (()=>{
        const isSentry = (typeof Sentry) !== 'undefined';

        switch(process.env.VUE_APP_SERVER_MODE){
            case "dev" : return false && isSentry;
            case "loc" : return false && isSentry;
            case "stg" : return true && isSentry;
            case "prd" : return true && isSentry;
            default : return false && isSentry;
        }
    })();

    enableSentry && Sentry.init({
        app,
        dsn: "https://92e6ac3c6a8c4956bfb9f01c9928aab5@o4504246532177920.ingest.sentry.io/4504372425719808",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "hrnflex.ph"],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        sampleRate : errorRate,
        tracesSampleRate : tracesRate,
    });
}

function postHogInitApp(app){
    // Analytics : PostHog - 개인화 추적시작. (로그인 된 상태에서)
    if( ! window.logOnProfile ) return;
    if( ! window.logOnProfile.data ) return;

    const {employeeSeq} = window.logOnProfile.data;

    const alreadyIdentified = getCookie('HR_POSTHOG_IDENTIFIED');
    if( alreadyIdentified ) return;

    const prefix = process.env.VUE_APP_POSTHOG_IDENTITY_PREFIX;
    posthog.identify(prefix + '_COM_USER_' + String(employeeSeq));
    posthog.capture(
        'COMPANY_SIGN_ON',
        {
            $set: {
                userNo: prefix + '_COM_USER_' + String(employeeSeq),
                channel: 'HR_COMPANY',
            }
        }
    );

    setCookieSession('HR_POSTHOG_IDENTIFIED', (new Date()).toISOString());
}
export {
    initApp
}

