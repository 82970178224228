<script>
import { contractType } from '@/assets/data/optionData.js';
import { ValidateUtil, CommonDataFormatUtil } from '@/plugins/app-util.js';
import moment from 'moment';
import ElementsModal2Button from '@/components/elements/ElementsModal2Button.vue';

export default {
    components: {
        ElementsModal2Button,
    },
    props: {
        employeeData: Object,
    },
    emits: ['afterSave'],
    data() {
        return {
            dateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
            todayText: moment().format('YYYYMMDD'),
            todayInt: null,
            employeeContractInfoHistorySeq: null,
            employeeSeq: null,
            companySeq: null,
            contractInfoHistoryList: [],
            employeePropsData: [],
            employmentTypeOptions: contractType,
            // modal
            showDeleteCheckModal: false,
            showEditPopup: false,
            showPopup: false,
            employee: {},
            noInfoPermission: false,
            editItemsContractInfo: {
                employeeSeq: null,
                companySeq: null,
                employeeContractInfoHistorySeq: null,
                contractInfoContent: null,
                employmentType: null,
                employeeContractInfoStartDatetime: null,
                employeeContractInfoEndDatetime: null,
                typeChecked: false,
            },
            popupItems: {
                employeeSeq: null,
                contractInfoContent: null,
                employmentType: null,
                employeeContractInfoStartDatetime: null,
                employeeContractInfoEndDatetime: null,
            },
        }; //return
    }, //data
    methods: {
        moment: moment,
        parseInt: parseInt,
        changedType : CommonDataFormatUtil.employmentTypeFormat,
        changeDateFormat(dateItem) {
            const changeDate = moment(dateItem).format('YYYYMMDD');
            return changeDate;
        },
        deleteEducationModalOpen(contractInfoHistoryItems) {
            const self = this;
            self.showEditPopup = false;
            self.showDeleteCheckModal = true;
            self.employeeContractInfoHistorySeq = contractInfoHistoryItems.employeeContractInfoHistorySeq;
        },
        //데이터 삭제
        deleteEducation() {
            const self = this;
            const employeeContractInfoHistorySeq = self.employeeContractInfoHistorySeq;
            // TODO:delete axios
            const url = self.$api('uri', 'delete-employee-contract-info-history').replace('{employeeContractInfoHistorySeq}', employeeContractInfoHistorySeq);
            self.$axios
                .delete(url)
                .then((res) => {
                    // console.log(res);
                    self.$emit('afterSave');
                    self.showDeleteCheckModal = false;
                    self.getEmployeeContractInfoData(self.employeeSeq);
                })
                .catch(console.log);
        },
        //edit save
        editContractInfoSave() {
            const self = this;

            const { employeeContractInfoHistorySeq, employeeContractInfoStartDatetime, employeeContractInfoEndDatetime, employmentType, contractInfoContent } = self.editItemsContractInfo;
            const requestItem = {
                employeeSeq : self.employeeSeq,
                employeeContractInfoHistorySeq,
                contractInfoContent,
                employmentType,
                employeeContractInfoStartDatetime,
                employeeContractInfoEndDatetime,
            };

            const url = self.$api('uri', 'put-employee-contract-info-history');
            self.$axios
                .put(`${url}/${encodeURIComponent(self.editItemsContractInfo.employeeContractInfoHistorySeq)}`, requestItem)
                .then((res) => {
                    self.showEditPopup = false;
                    self.getEmployeeContractInfoData();
                    self.$emit('afterSave');
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        //open Modal
        clickEditButton(arg) {
            const self = this;
            self.showEditPopup = true;

            self.editItemsContractInfo = { ...arg };

            if (self.editItemsContractInfo.employmentType == 'FULL_TIME' || self.editItemsContractInfo.employmentType == 'MIGRANT_AND_OVERSEAS') {
                self.editItemsContractInfo.typeChecked = false;
            }
        },
        allClose() {
            const self = this;
            self.showDeleteCheckModal = false;
            self.showEditPopup = false;
        },
        getEmployeeContractInfoData() {
            const self = this;
            const url = self.$api('uri', 'get-employee-contract-info-history').replace('{employeeSeq}', self.employeeSeq);

            self.$axios
                .get(url)
                .then((res) => {
                    self.contractInfoHistoryList = res.data.data.list;
                    let i = 0;
                    for (i; i < self.contractInfoHistoryList.length; i++) {
                        if (self.contractInfoHistoryList[i].employeeContractInfoStartDatetime != null) {
                            let updateDate = self.parseInt(moment(self.contractInfoHistoryList[i].employeeContractInfoStartDatetime).format('YYYYMMDD'));
                            self.contractInfoHistoryList[i]['intEmployeeContractInfoStartDatetime'] = updateDate;
                            // intEmployeeContractInfoStartDatetime 이 today 보다 크면 on going
                            // intEmployeeContractInfoEndDatetime 이 today 보다 크면 Done
                        }
                        if (self.contractInfoHistoryList[i].employeeContractInfoEndDatetime != null) {
                            let updateDate = self.parseInt(moment(self.contractInfoHistoryList[i].employeeContractInfoEndDatetime).format('YYYYMMDD'));
                            self.contractInfoHistoryList[i]['intEmployeeContractInfoEndDatetime'] = updateDate;
                            // intEmployeeContractInfoStartDatetime 이 today 보다 작으면 Intended
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        saveContractInfo() {
            const self = this;
            const employeeSeq = self.employeeSeq;
            const requestItem = { ...self.popupItems, employeeSeq };
            const url = self.$api('uri', 'post-employee-contract-info-history');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.$emit('afterSave');
                    self.showPopup = false;
                    self.getEmployeeContractInfoData();
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        addSubmitRequestAfterConfirm() {
            const self = this;
            const startDate = self.popupItems.employeeContractInfoStartDatetime;
            const endDate = self.popupItems.employeeContractInfoEndDatetime;
            const employeeSeq = self.employeeSeq;
            
            if(ValidateUtil.checkIsEmpty(startDate)){
                alert('Start Date can’t be blank');
                    return;
            }
            
            if (self.popupItems.employmentType === null) {
                alert('Employment Type can’t be blank');
                return;
            }
            
            if (self.requiredEndDate) {
                if (ValidateUtil.checkIsEmpty(endDate)) {
                    alert('End Date can’t be blank');
                    return;
                }
            }

            if ( startDate > endDate ) {
                alert('Start date must be prior to the end date.');
                return;
            }
            
            if (ValidateUtil.checkIsEmpty(self.popupItems.contractInfoContent)) {
                alert('Notes can’t be blank');
                return;
            }
            
            const requestItem = { startDate, endDate, employeeSeq };
            // duplication check
            const url = self.$api('uri', 'post-check-contract-info-period-duplication');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    const {isOnRegular, isOverlap}  = res.data.data;
                    if( isOnRegular ){
                        alert("Please enter the End Date for the existing contract type to add a new one.");
                        return;
                    }
                    if( isOverlap ){
                        alert('Contract periods cannot overlap with existing contract. Please check again.');
                        return;
                    }
                    
                    // early return if 없이 
                    self.saveContractInfo();
                        
                })
                .catch((err) => {
                    console.log('fail: ', err);
                });
        },
        editSubmitRequestAfterConfirm() {
            const self = this;

            const { employeeContractInfoHistorySeq, employeeContractInfoStartDatetime, employeeContractInfoEndDatetime, employmentType, contractInfoContent } = self.editItemsContractInfo;
            const employeeSeq = self.employeeSeq;

            if (ValidateUtil.checkIsEmpty(contractInfoContent)) {
                alert('Notes can’t be blank');
                return;
            }

            if (employmentType === null) {
                alert('Employment Type can’t be blank');
                return;
            }
            
            if(ValidateUtil.checkIsEmpty(employeeContractInfoStartDatetime)){
                alert('Start Date can’t be blank');
                    return;
            }
            
            if (self.requiredEndDate) {
                if (ValidateUtil.checkIsEmpty(employeeContractInfoEndDatetime)) {
                    alert('End Date can’t be blank');
                    return;
                }
            }
            
            if (employeeContractInfoStartDatetime > employeeContractInfoEndDatetime) {
                alert('Start date must be prior to the end date.');
                return;
            }
            
            const requestItem = { 
                startDate : employeeContractInfoStartDatetime, 
                endDate : employeeContractInfoEndDatetime, 
                employeeSeq, 
                employeeContractInfoHistorySeq 
            };

            // duplication check
            const url = self.$api('uri', 'post-check-contract-info-period-duplication');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    const {isOnRegular, isOverlap}  = res.data.data;
                    if( isOnRegular ){
                        alert("Please enter the End Date for the existing contract type to add a new one.");
                        return;
                    }
                    if( isOverlap ){
                        alert('Contract periods cannot overlap with existing contract. Please check again.');
                        return;
                    }
                    
                    self.editContractInfoSave();

                })
                .catch((err) => {
                    console.log('fail: ', err);
                });
        },
        addButtonClick() {
            const self = this;
            //초기화
            self.popupItems = {
                employeeSeq: null,
                contractInfoContent: null,
                employmentType: null,
                employeeContractInfoStartDatetime: null,
                employeeContractInfoEndDatetime: null,
            };

            self.showPopup = true;
        },
        clickOptionButton(contractInfo) {
            if (contractInfo.isShow) {
                contractInfo.isShow = false;
            } else {
                contractInfo.isShow = true;
            }
        },
    },
    mounted() {
        const self = this;
        self.todayInt = self.parseInt(self.todayText);
        self.employeePropsData = self.employeeData;
        self.employeeSeq = self.employeePropsData.employeeSeq;
        self.companySeq = self.employeePropsData.companySeq;

        self.getEmployeeContractInfoData();
    },
    computed: {
        requiredEndDate() {
            let isRequired = false;
            const self = this;

            let employmentType = null;

            if (self.showEditPopup == true) {
                employmentType = self.editItemsContractInfo.employmentType;
            } else {
                employmentType = self.popupItems.employmentType;
            }

            switch (employmentType) {
                case 'FULL_TIME':
                    isRequired = false;
                    break;
                case 'PROBATION':
                    isRequired = true;
                    break;
                case 'FIXED_TERM_CONTRACT':
                    isRequired = true;
                    break;
                case 'PROJECT_BASE':
                    isRequired = true;
                    break;
                case 'OUTSOURCE':
                    isRequired = true;
                    break;
                case 'TEMPORARY':
                    isRequired = true;
                    break;
            }

            return isRequired;
        },
    },
};
</script>

<template>
    <!-- edit modal -->
    <Teleport to="body">
        <AppPopup v-model="showEditPopup" name="Contract Infomation" :width800="true">
            <div class="pr-7 pb-8 pl-7">
                <div class="flex justify-between items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">Employment Type<span class="text-red-500">*</span></div>
                    <div class="flex">
                        {{ changedType(editItemsContractInfo.employmentType) }}
                    </div>
                </div>
                <div class="flex justify-between items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">Start Date<span class="text-red-500">*</span></div>
                    <ElementsDatePicker v-model="editItemsContractInfo.employeeContractInfoStartDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" class="w-full pl-3" />
                </div>
                <div class="flex justify-between items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">End Date<span v-if="requiredEndDate" class="text-red-500">*</span></div>
                    <ElementsDatePicker v-model="editItemsContractInfo.employeeContractInfoEndDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" class="w-full pl-3" />
                </div>
                <div class="flex justify-between items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">Notes<span class="text-red-500">*</span></div>
                    <ElementsInput v-model="editItemsContractInfo.contractInfoContent" class="flex-1" :full="true" />
                </div>
            </div>
            <div class="flex justify-end p-4 border-t bg-white-200">
                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showEditPopup = false" />
                <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="editSubmitRequestAfterConfirm()" />
            </div>
        </AppPopup>
        <ElementsModal2Button
            v-model="showDeleteCheckModal"
            :modalTitle="'Delete Check'"
            :buttonText="'Confirm'"
            :modalContent="'Are you sure to want to delete this Contract Information'"
            @afterClick="deleteEducation" />
        <AppPopup v-model="showPopup" name="Contract Information" :width800="true">
            <div class="pr-7 pb-8 pl-7">
                <div class="flex justify-between items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">Employment Type</div>
                    <ElementsSelectRef v-model="popupItems.employmentType" placeholder="Select Employment Type" class="flex-1" :options="employmentTypeOptions" :full="true" />
                </div>
                <div class="flex items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">Start Date<span class="text-red-500">*</span></div>
                    <ElementsDatePicker v-model="popupItems.employeeContractInfoStartDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" class="w-full pl-3" />
                </div>
                <div class="flex items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">End date<span v-if="requiredEndDate" class="text-red-500">*</span></div>
                    <ElementsDatePicker v-model="popupItems.employeeContractInfoEndDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" class="w-full pl-3" />
                </div>
                <div class="flex justify-between items-center gap-2 mt-6">
                    <div class="w-32 text-sm font-semibold text-slate-800">Notes<span class="text-red-500">*</span></div>
                    <ElementsInput v-model="popupItems.contractInfoContent" class="flex-1" :maxlength="60" :full="true" />
                </div>
            </div>
            <!-- add button -->
            <div class="flex w-full bg-white">
                <div class="w-full p-4 border-t bg-white-200">
                    <div class="text-right justify-end flex">
                        <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showPopup = false" />
                        <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="addSubmitRequestAfterConfirm" />
                    </div>
                </div>
            </div>
        </AppPopup>
    </Teleport>
    <div class="flex justify-between items-center mt-6 h-10">
        <h3 class="text-xl font-bold">Contract Information</h3>
        <button 
            v-if="$appUtil.checkPermission('DASHBOARD_MANAGEMENT_EDIT') || $appUtil.checkPermission('JOB_INFO_EDIT')"
            class="border rounded border-blue-500 w-24 h-10 bg-white text-sm font-semibold text-blue-500 hover:bg-blue-100 transition-all duration-500 text-center" @click="addButtonClick">
            Add
        </button>
    </div>
    <span>Contract Information List</span>
    <div class="border-b border-b-[#D6DDEB] px-7 py-5 min-h-[416px]">
        <template v-for="(contractInfoHistory, index) in contractInfoHistoryList" :key="index">
            <div class="flex-1 justify-between border border-[#D6DDEB] rounded-lg px-5 py-6 mb-4 relative">
                <div class="text-right justify-end flex" v-if="$appUtil.checkPermission('DASHBOARD_MANAGEMENT_EDIT') || $appUtil.checkPermission('JOB_INFO_EDIT')">
                    <img @click="clickOptionButton(contractInfoHistory)" class="h-5 w-5 cursor-pointer rotate-90" src="@/assets/img/option.svg" alt="" />
                </div>
                <p class="truncate relative block items-center mb-1 text-sm font-normal">
                    <span v-if="contractInfoHistory.intEmployeeContractInfoStartDatetime > todayInt" class="bg-orange-400 text-xs mr-2 px-2.5 py-0.5 text-white rounded">Intended</span>
                    <span
                        v-if="contractInfoHistory.intEmployeeContractInfoStartDatetime <= todayInt && contractInfoHistory.intEmployeeContractInfoEndDatetime >= todayInt"
                        class="bg-purple-700 text-white text-xs mr-2 px-2.5 py-0.5 rounded"
                        >On going</span
                    >
                    <span
                        v-if="contractInfoHistory.intEmployeeContractInfoStartDatetime < todayInt && contractInfoHistory.intEmployeeContractInfoEndDatetime < todayInt"
                        class="bg-gray-400 text-white text-xs mr-2 px-2.5 py-0.5 rounded"
                        >Done</span
                    >
                </p>
                <p class="font-bold text-base">{{ changedType(contractInfoHistory.employmentType) }}</p>
                <span class="text-base"> {{ moment(contractInfoHistory.employeeContractInfoStartDatetime).format('MM/DD/YYYY') }}</span>
                <span v-if="contractInfoHistory.employeeContractInfoEndDatetime" class="text-base"> ~ {{ moment(contractInfoHistory.employeeContractInfoEndDatetime).format('MM/DD/YYYY') }}</span>
                <p class="text-base mt-5">{{ contractInfoHistory.contractInfoContent }}</p>
                <transition
                    class="z-30"
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95">
                    <div v-if="contractInfoHistory.isShow" class="absolute top-12 right-4 border rounded border-gray-200 shadow-md shadow-gray-200 w-56 bg-white">
                        <p
                            @click="clickEditButton(contractInfoHistory)"
                            class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">
                            Edit
                        </p>
                        <p
                            @click="deleteEducationModalOpen(contractInfoHistory)"
                            class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">
                            Delete
                        </p>
                    </div>
                </transition>
            </div>
        </template>
    </div>
</template>
