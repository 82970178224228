<script>
import { contractType } from '@/assets/data/optionData.js';
import AppPopup from '@/components/AppPopup.vue';
import moment from 'moment';
import ElementsModal2Button from '@/components/elements/ElementsModal2Button.vue';
import { ValidateUtil, CommonDataFormatUtil } from '@/plugins/app-util.js';

export default {
    components: {
        AppPopup,
        ElementsModal2Button,
    },
    props: {
        selectedEmployeeSeq: String,
    },
    data() {
        return {
            // 공통 사용
            userSeq: null,
            nowSave: false,
            employeeSeq: null,
            qualifications: [],
            qualificationSeq: null,
            certificateFile: null,
            popupEditItemsQualification: {
                qualificationSeq: null,
                employeeSeq: null,
                qualificationName: null,
                institution: null,
                qualificationDatetime: null,
                certificateFileName: null,
                certificateFile: null,
            },
            showEditBtn: false,
            // 인풋 값
            filePath: null,
            inputFilePath: null,
            inputFileName: null,
            qualification: {
                status: null,
                file: null,
                qualifications: [],
                request: {
                    employeeSeq: null,
                    qualificationName: null,
                    institution: null,
                    qualificationDatetime: null,
                    certificateFileName: null,
                    certificateFile: null,
                },
            },
            //modal
            showPopupQualification: false,
            showEditPopupQualification: false,
            showCancelCheckModalQualification: false,
            showCancelCheckModalCareer: false,
            showPopupCareer: false,
            showEditPopupCareer: false,
            //career
            careerSeq: null,
            contractTypeOptions: contractType,
            //db data
            careers: [],
            popupEditItemsCareer: {
                employeeSeq: null,
                companyName: null,
                departmentName: null,
                jobTitleName: null,
                workFirstDate: null,
                workLastDate: null,
                contractType: null,
            },
            // add
            career: {
                request: {
                    employeeSeq: null,
                    companyName: null,
                    departmentName: null,
                    jobTitleName: null,
                    workFirstDate: null,
                    workLastDate: null,
                    contractType: null,
                },
            },
        }; // return
    }, // data
    mounted() {
        const self = this;
        self.getListCareer();
        self.getListQualification();
    },
    methods: {
        changeEmployeeType : CommonDataFormatUtil.employmentTypeFormat,
        fileNameConverter(filePath) {
            const params = new URLSearchParams(filePath);
            let fileName = params.get('showName');

            return fileName ? decodeURIComponent(fileName) : '-';
        },
        moment: moment,
        parseInt: parseInt,
        // 공통
        close() {
            const self = this;
            self.showPopupQualification = false;
            self.showEditPopupQualification = false;
            self.showCancelCheckModalQualification = false;
            self.openPhotoView = false;
            self.nowSave = false;

            self.showPopupCareer = false;
            self.showEditPopupCareer = false;
            self.showCancelCheckModalCareer = false;

            self.getListCareer();
            self.getListQualification();
        },
        getListQualification() {
            const self = this;
            const url = self.$api('uri', 'get-employee-qualification').replace('{employeeSeq}', encodeURIComponent(self.selectedEmployeeSeq));
            const json_query = JSON.stringify({
                employeeSeq: self.selectedEmployeeSeq,
            });
            self.$axios
                .get(url, { params: { json_query } })
                .then((res) => {
                    self.request = res.data.data;
                    const { data } = res;
                    self.qualifications = data.data.list;
                    self.showEditBtn = true;
                })
                .catch((err) => (self.showEditBtn = false));
        },
        //get-info api 사용안함
        getQualificationEdit(arg) {
            const self = this;
            self.showEditPopupQualification = true;
            self.qualification.status = 'edit';

            self.popupEditItemsQualification = { ...arg };
            self.qualificationSeq = self.popupEditItemsQualification.qualificationSeq;
        },
        deleteQualificationModalOpen() {
            const self = this;
            self.close();

            self.showCancelCheckModalQualification = true;
        },
        deleteQualification() {
            const self = this;
            const qualificationSeq = self.popupEditItemsQualification.qualificationSeq;
            const url = self.$api('uri', 'delete-employee-qualification').replace('{qualificationSeq}', encodeURIComponent(qualificationSeq));
            self.$axios
                .delete(url)
                .then((res) => {
                    self.deleteFile(self.popupEditItemsQualification.certificateFile, true);
                    self.close();
                })
                .catch(console.log);
        },
        //add button click
        addQualification() {
            const self = this;
            self.showPopupQualification = true;
            self.nowSave = true;

            //init
            self.qualificationSeq = null;
            self.qualification.request.qualificationName = null;
            self.qualification.request.institution = null;
            self.qualification.request.qualificationDatetime = null;
            self.qualification.request.certificateFileName = null;
            self.qualification.request.certificateFile = null;
        },
        //add save button click
        saveQualification() {
            const self = this;
            if (ValidateUtil.checkIsEmpty(self.qualification.request.qualificationName)) {
                alert('Certificate Name can’t be blank');
                return;
            }

            if (ValidateUtil.checkIsEmpty(self.qualification.request.institution)) {
                alert('Institution can’t be blank');
                return;
            }

            if (ValidateUtil.checkIsEmpty(self.qualification.request.qualificationDatetime)) {
                alert('Date can’t be blank');
                return;
            }

            if (self.inputFilePath == null) {
                alert('Certificate File can’t be blank');
                return;
            }
            self.nowSave = false;

            self.submitAfterFileUpload(self.inputFilePath, self.inputFileName);
        },
        //post
        postQualification() {
            const self = this;

            let requestItem = { ...self.qualification.request };
            requestItem['employeeSeq'] = self.selectedEmployeeSeq;

            const url = self.$api('uri', 'post-employee-qualification');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.qualificationSeq = res.data.data.qualificationSeq;

                    //init
                    self.inputFilePath = null;
                    self.inputFileName = null;

                    self.close();
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        //put
        putQualification() {
            const self = this;
            const requestItem = { ...self.popupEditItemsQualification };
            const url = self.$api('uri', 'put-employee-qualification').replace('{qualificationSeq}', encodeURIComponent(self.qualificationSeq));

            self.$axios
                .put(url, requestItem)
                .then((res) => {
                    //init
                    self.inputFilePath = null;
                    self.inputFileName = null;

                    self.close();
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        //edit save button click
        editSaveQualification() {
            const self = this;

            if (ValidateUtil.checkIsEmpty(self.popupEditItemsQualification.qualificationName)) {
                alert('Certificate Name can’t be blank');
                return;
            }

            if (ValidateUtil.checkIsEmpty(self.popupEditItemsQualification.institution)) {
                alert('Institution can’t be blank');
                return;
            }

            if (ValidateUtil.checkIsEmpty(self.popupEditItemsQualification.qualificationDatetime)) {
                alert('Date can’t be blank');
                return;
            }

            if (self.popupEditItemsQualification.certificateFile == null) {
                alert('Certificate File can’t be blank');
                return;
            }

            if (self.inputFilePath == null) {
                self.putQualification();
                return;
            }

            self.submitAfterFileUpload(self.inputFilePath, self.popupEditItemsQualification.certificateFileName);
        },
        inputFile(file) {
            const self = this;
            //init
            self.inputFilePath = null;
            self.inputFileName = null;

            if (!file) {
                return;
            }

            self.inputFilePath = file;
            self.inputFileName = file.name;
            self.popupEditItemsQualification.certificateFileName = file.name;
            self.qualification.request.certificateFileName = file.name;
        },
        submitAfterFileUpload(filePath, fileName) {
            const self = this;

            const url = self.$api('uri', 'post-file-direct-upload');

            let form = new FormData();
            form.append(`uploadFile1`, filePath);
            form.append(`uploadFileName`, fileName);

            self.$axios
                .post(url, form, { headers: { 'Content-Type': 'multipart/form-data;' } })
                .then((res) => {
                    if (self.qualificationSeq == null) {
                        self.qualification.request.certificateFile = res.data.data.uploadFile1;
                        self.postQualification();
                        return;
                    }
                    self.popupEditItemsQualification.certificateFile = res.data.data.uploadFile1;
                    self.putQualification();
                })
                .catch((err) => {
                    console.error(err);
                    alert(' fail ');
                });
        },
        deleteFile(filePath, isEdit = false) {
            const self = this;
            self.inputFileName = null;
            self.inputFilePath = null;
            if (isEdit) {
                self.popupEditItemsQualification.certificateFile = null;
                self.popupEditItemsQualification.certificateFileName = null;
            }

            if (!filePath) {
                return;
            }
            self.$axios
                .delete(filePath)
                .then((res) => {})
                .catch((err) => {
                    console.error(err);
                });
        },
        //career
        deleteCareer() {
            const self = this;
            const params = new URLSearchParams(document.location.search);
            params.append('careerSeq', self.careerSeq);
            const careerSeq = params.get('careerSeq');
            const url = self.$api('uri', 'delete-employee-career');
            self.$axios
                .delete(`${url}/${careerSeq}`)
                .then((res) => {
                    self.close();
                })
                .catch(console.log);
        },
        deleteCareerModalOpen() {
            const self = this;
            self.modelValue = false;
            self.showEditModal = false;
            //== 해당 변수 없음
            // self.modelValueQualification = false;
            // self.showEditModalQualification = false;
            self.showCancelCheckModalCareer = true;
        },
        saveCareerInfo() {
            const self = this;
            const requestItem = { ...self.career.request };
            requestItem['employeeSeq'] = self.selectedEmployeeSeq;

            if (ValidateUtil.checkIsEmpty(requestItem.companyName)) {
                alert('Company Name can’t be blank');
                return;
            }

            if (parseInt(requestItem.workLastDate) < parseInt(requestItem.workFirstDate)) {
                alert('Please enter the correct date');
                return;
            }

            const url = self.$api('uri', 'post-employee-career');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.nowSave = false;
                    self.showPopupCareer = false;
                    self.getListCareer();
                    self.showEditBtn = true;
                })
                .catch((err) => {
                    console.log(err.response);
                    self.showEditBtn = false;
                });
        },
        editSaveButtonClick() {
            const self = this;
            const requestItem = { ...self.popupEditItemsCareer };

            if (ValidateUtil.checkIsEmpty(requestItem.companyName)) {
                alert('Company Name can’t be blank');
                return;
            }

            if (parseInt(requestItem.workLastDate) < parseInt(requestItem.workFirstDate)) {
                alert('Please enter the correct date');
                return;
            }

            const url = self.$api('uri', 'put-employee-career');
            self.$axios
                .put(`${url}/${encodeURIComponent(self.careerSeq)}`, requestItem)
                .then((res) => {
                    self.showEditPopupCareer = false;
                    self.getListCareer();
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        editButtonClick(arg) {
            const self = this;
            self.showEditPopupCareer = true;

            //init
            self.popupEditItemsCareer.companyName = '';
            self.popupEditItemsCareer.departmentName = '';
            self.popupEditItemsCareer.jobTitleName = '';
            self.popupEditItemsCareer.workFirstDate = '';
            self.popupEditItemsCareer.workLastDate = '';
            self.popupEditItemsCareer.contractType = null;

            self.beforeEditData = { ...arg };
            self.popupEditItemsCareer = self.beforeEditData;
            self.careerSeq = arg.careerSeq;
        },
        cancelCareerButtonClick() {
            const self = this;
            self.showPopupCareer = false;
            self.nowSave = false;
        },
        addCareerButtonClick() {
            const self = this;
            self.showPopupCareer = true;
            self.nowSave = true;

            //init
            self.career.request.companyName = '';
            self.career.request.departmentName = '';
            self.career.request.jobTitleName = '';
            self.career.request.workFirstDate = '';
            self.career.request.workLastDate = '';
            self.career.request.contractType = null;
        },
        getListCareer() {
            const self = this;
            const url = self.$api('uri', 'get-employee-career').replace('{employeeSeq}', encodeURIComponent(self.selectedEmployeeSeq));
            const json_query = JSON.stringify({
                employeeSeq: self.selectedEmployeeSeq,
            });
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                self.request = res.data.data;
                const { data } = res;
                self.careers = data.data.list;
            });
        },
    },
}; // return
</script>
<template>
    <div>
        <!-- career list -->
        <div>
            <div class="flex justify-between items-center mt-6 h-10">
                <h4 class="text-lg font-bold">Work Experience({{ careers.length }})</h4>
                <button
                    class="border rounded border-blue-500 w-24 h-10 bg-white text-sm font-semibold text-blue-500 hover:bg-blue-100 transition-all duration-500 text-center"
                    @click="addCareerButtonClick()"
                    v-show="$appUtil.checkPermission('CAREER_QUALIFI_INFO_EDIT') && showEditBtn">
                    Add
                </button>
            </div>
            <div v-for="(career, index) in careers" v-bind:key="index">
                <div class="h-14 text-sm font-bold flex justify-between items-center">
                    <h5>{{ career.companyName }}</h5>
                    <button class="px-2 text-indigo-600" @click="editButtonClick(career)" v-show="$appUtil.checkPermission('CAREER_QUALIFI_INFO_EDIT') && showEditBtn">Edit</button>
                </div>
                <div class="border rounded-lg border-color-gray-200">
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Department</h5>
                        <p v-if="!career.departmentName" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ career.departmentName }}
                        </p>
                    </div>
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Tier</h5>
                        <p v-if="!career.jobTitleName" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ career.jobTitleName }}
                        </p>
                    </div>
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">First day of work</h5>
                        <p v-if="!career.workFirstDate" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ moment(career.workFirstDate, 'YYYYMMDD').format('MM/DD/YYYY') }}
                        </p>
                    </div>
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Last day of work</h5>
                        <p v-if="!career.workLastDate" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ moment(career.workLastDate, 'YYYYMMDD').format('MM/DD/YYYY') }}
                        </p>
                    </div>
                    <div class="h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Contract Type</h5>
                        <p v-if="!career.contractType" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ changeEmployeeType(career.contractType) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex justify-between items-center mt-6 h-10">
                <h4 v-if="qualifications != null" class="text-lg font-bold">Certificate({{ qualifications.length }})</h4>
                <button
                    class="border rounded border-blue-500 w-24 h-10 bg-white text-sm font-semibold text-blue-500 hover:bg-blue-100 transition-all duration-500 text-center"
                    @click="addQualification()"
                    v-show="$appUtil.checkPermission('CAREER_QUALIFI_INFO_EDIT') && showEditBtn">
                    Add
                </button>
            </div>
        </div>
        <!-- edit career -->
        <Teleport to="body">
            <AppPopup v-model="showEditPopupCareer" name="Edit Work Experience" @afterClose="getListCareer()">
                <div class="pr-7 pb-8 pl-7">
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Company Name</div>
                        <ElementsInput v-model="popupEditItemsCareer.companyName" placeholder="Enter Company name" class="flex-1" :full="true" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Department</div>
                        <ElementsInput v-model="popupEditItemsCareer.departmentName" placeholder="Enter Department" class="flex-1" :full="true" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Tier</div>
                        <ElementsInput v-model="popupEditItemsCareer.jobTitleName" placeholder="Enter Tier" class="flex-1" :full="true" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">First Day Of Work</div>
                        <ElementsDatePicker v-model="popupEditItemsCareer.workFirstDate" placeholder="Select Date" class="flex-1" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Last Day Of Work</div>
                        <ElementsDatePicker v-model="popupEditItemsCareer.workLastDate" placeholder="Select Date" class="flex-1" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Contract Type</div>
                        <ElementsSelectRef
                            v-model="popupEditItemsCareer.contractType"
                            placeholder="Select Contract Type"
                            class="flex-1"
                            :options="contractTypeOptions"
                            :full="true"
                            :useDefaultMsg="true" />
                    </div>
                </div>
                <div class="flex justify-end p-4 border-t bg-white-200">
                    <ElementsButton text="Delete" :width28="true" :height12="true" :background-red="true" @click="deleteCareerModalOpen()" />
                    <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="editSaveButtonClick()" />
                </div>
            </AppPopup>
            <!-- edit delete check modal -->
            <ElementsModal2Button v-model="showCancelCheckModalCareer" :modalTitle="'Delete Check'" :buttonText="'Confirm'" :modalContent="'Are you sure to Delete?'" @afterClick="deleteCareer" />
        </Teleport>
        <!-- add career -->
        <Teleport to="body">
            <AppPopup v-model="showPopupCareer" name="Add Work Experience" @afterClose="getListCareer()">
                <div class="pr-7 pb-8 pl-7">
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Company Name</div>
                        <ElementsInput v-model="career.request.companyName" placeholder="Enter Company Name" class="flex-1" :full="true" style="width: 420px" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Department</div>
                        <ElementsInput v-model="career.request['departmentName']" placeholder="Enter Department" class="flex-1" :full="true" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Tier</div>
                        <ElementsInput v-model="career.request['jobTitleName']" placeholder="Enter Tier" class="flex-1" :full="true" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">First day of work</div>
                        <ElementsDatePicker v-model="career.request.workFirstDate" placeholder="Select Date" class="flex-1" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Last day of work</div>
                        <ElementsDatePicker v-model="career.request.workLastDate" placeholder="Select Date" class="flex-1" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Contract Type</div>
                        <ElementsSelectRef v-model="career.request.contractType" placeholder="Select Contract Type" class="flex-1" :options="contractTypeOptions" :full="true" />
                    </div>
                </div>
                <!-- add button -->
                <div class="flex justify-end p-4 border-t bg-white-200">
                    <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="close()" />
                    <ElementsButton v-show="nowSave" class="ml-2" text="Save" :width28="true" :height12="true" @click="saveCareerInfo()" />
                </div>
            </AppPopup>
        </Teleport>
        <!-- Qualification list -->
        <div>
            <div v-for="(qualification, index) in qualifications" v-bind:key="index">
                <div class="h-14 text-sm font-bold flex justify-between items-center">
                    <h5>{{ qualification.qualificationName }}</h5>
                    <button class="px-2 text-indigo-600" @click="getQualificationEdit(qualification)" v-show="$appUtil.checkPermission('CAREER_QUALIFI_INFO_EDIT') && showEditBtn">Edit</button>
                </div>
                <div class="border rounded-lg border-color-gray-200">
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Institution</h5>
                        <p v-if="!qualification.institution" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ qualification.institution }}
                        </p>
                    </div>
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Date</h5>
                        <p v-if="!qualification.qualificationDatetime" class="font-medium text-gray-400 w-2/5">-</p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ moment(qualification.qualificationDatetime).format('MM/DD/YYYY') }}
                        </p>
                    </div>
                    <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                        <h5 class="font-bold w-3/5">Certificate File</h5>
                        <template v-if="!qualification.certificateFileName">
                            <p class="font-medium text-gray-400 w-2/5">-</p>
                        </template>
                        <template v-else>
                            <a :href="qualification.certificateFile">
                                <p style="white-space: pre-line" class="font-medium text-blue-500 w-2/5">
                                    {{ qualification.certificateFileName }}
                                </p>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- add  Qualification -->
        <Teleport to="body">
            <div id="showPopupQualification">
                <AppPopup v-model="showPopupQualification" name="Add Certificate" @afterClose="close()">
                    <div class="pr-7 pb-8 pl-7">
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Certificate Name</div>
                            <ElementsInput v-model="qualification.request['qualificationName']" placeholder="Enter Certificate Name" class="flex-1" :full="true" style="width: 420px" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Institution</div>
                            <ElementsInput v-model="qualification.request['institution']" placeholder="Enter Institution" class="flex-1" :full="true" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Date</div>
                            <ElementsDatePicker v-model="qualification.request.qualificationDatetime" placeholder="Select Date" class="flex-1" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Certificate File</div>
                            <div class="w-[27rem] flex justify-between gap-2">
                                <ElementsInput v-model="qualification.request['certificateFileName']" placeholder="Choose File" :readonly="true" :full="true" class="flex-1" />

                                <div class="flex justify-center items-center">
                                    <img src="@/assets/img/delete.svg" width="14" height="14" class="ml-2 cursor-pointer" v-show="inputFileName" @click="deleteFile()" />
                                </div>

                                <a v-show="!inputFileName" href="javascript:void(0)" class="flex items-center" @click="$refs.file.click">
                                    <img src="@/assets/img/browse.svg" />
                                    <span class="ml-0.5 text-sm font-normal text-blue-500">Browse</span>
                                    <input type="file" class="hidden" ref="file" @input="inputFile($event.target.files[0])" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end p-4 border-t bg-white-200">
                        <ElementsButton v-show="nowSave" text="Cancel" :width28="true" :height12="true" :background-white="true" @click="close()" />
                        <ElementsButton v-show="nowSave" class="ml-2" text="Save" :width28="true" :height12="true" @click="saveQualification()" />
                    </div>
                </AppPopup>
            </div>
        </Teleport>
        <!-- edit Qualification -->
        <Teleport to="body">
            <div id="showEditPopupQualification">
                <AppPopup v-model="showEditPopupQualification" name="Edit Certificate" @afterClose="close()">
                    <div class="pr-7 pb-24 pl-7">
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Certificate Name</div>
                            <ElementsInput v-model="popupEditItemsQualification['qualificationName']" placeholder="Enter Certificate Name" class="flex-1" :full="true" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Institution</div>
                            <ElementsInput v-model="popupEditItemsQualification['institution']" placeholder="Enter Institution" class="flex-1" :full="true" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Date</div>
                            <ElementsDatePicker v-model="popupEditItemsQualification.qualificationDatetime" placeholder="Select Date" class="flex-1" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Certificate File</div>
                            <ElementsInput v-model="popupEditItemsQualification['certificateFileName']" placeholder="Choose File" :readonly="true" :full="true" class="flex-1" />
                            <img
                                src="@/assets/img/delete.svg"
                                width="14"
                                height="14"
                                class="ml-2 cursor-pointer"
                                v-show="popupEditItemsQualification.certificateFileName"
                                @click="deleteFile(popupEditItemsQualification.certificateFile, true)" />

                            <a v-show="!popupEditItemsQualification.certificateFileName" href="javascript:void(0)" class="flex items-center" @click="$refs.file.click">
                                <img src="@/assets/img/browse.svg" />
                                <span class="ml-0.5 text-sm font-normal text-blue-500">Browse</span>
                                <input type="file" class="hidden" ref="file" @input="inputFile($event.target.files[0])" />
                            </a>
                        </div>
                    </div>
                    <div class="p-4 flex justify-end items-felx-end w-full bg-white border-t bg-white-200">
                        <ElementsButton text="Delete" :width28="true" :height12="true" :background-red="true" @click="deleteQualificationModalOpen()" />
                        <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="editSaveQualification()" />
                    </div>
                </AppPopup>
            </div>
            <ElementsModal2Button
                v-model="showCancelCheckModalQualification"
                :modalTitle="'Delete Check'"
                :buttonText="'Confirm'"
                :modalContent="'Are you sure to Delete?'"
                @afterClick="deleteQualification" />
        </Teleport>
    </div>
</template>
