<script>
import ElementsModal from '@/components/elements/ElementsModal.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';
import AppPopupHeaderless from '@/components/AppPopupHeaderless';

export default {
    components: {
        ElementsModal,
        ConfirmPopup,
        AppPopupHeaderless,
    },
    props: {
        transfer: Boolean,
        deduct: Boolean,
    },
    emits: ['clickTransferButton', 'clickDeductButton'],
    data() {
        return {
            availableWalletAmount: 0,

            totalAmount: 0,

            emailMessage: '',
            uploadFile: null,

            excelList: [],
            failedList: [],

            showFailedListPopup: false,
            showAlertPopup: false,
            alertPopupMessage: null,
            templateFileName: null,
            showConfirmPopup: false,
            confirmMessage: '',
            showInvalidAmountPopup: false,
        };
    },
    mounted() {
        const self = this;

        if (self.transfer) {
            self.templateFileName = 'transferTemplateFile.xlsx';
        }

        if (self.deduct) {
            self.templateFileName = 'deductTemplateFile.xlsx';
        }
        self.getAvailablePoint();
    },
    methods: {
        convertCommaStringToNumber(value) {
            if (value == null || value === '') {
                return '';
            }
            return Number(String(value).replaceAll(',', ''));
        },
        convertNumberToCommaString(value) {
            let decimalValue = String(value);
            decimalValue = decimalValue.replace(/(\..*)\./g, '$1'); // 첫 번째 소수점 이후에 나오는 추가 소수점을 제거

            const parts = decimalValue.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        },
        numberWithCommas(number) {
            if (number == null || Number.isNaN(Number(number))) {
                return number;
            }

            return this.convertNumberToCommaString(number);
        },
        getAvailablePoint() {
            const self = this;

            const url = self.$api('uri', 'get-availablePoint');
            self.$axios
                .get(url)
                .then((res) => {
                    self.availableWalletAmount = res.data.data.availableWalletAmount;
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        clickDeleteButton(index) {
            const self = this;

            self.excelList.splice(index, 1);

            self.calTotalAmount();
        },
        clickTransferButton() {
            const self = this;

            // total amount 가 가지고 있는 것보다 큰 경우
            if (self.totalAmount > self.availableWalletAmount) {
                self.showAlertPopup = true;
                self.alertPopupMessage = 'The amount of transfer is more than total';
                return;
            }

            if (self.checkValidation() != 0) {
                return;
            }

            self.openConfirmPopup('Are you sure you want to transfer points?');
        },
        clickDeductButton() {
            const self = this;

            // total amount 가 가지고 있는 것보다 큰 경우
            if (self.totalAmount > self.availableWalletAmount) {
                self.showAlertPopup = true;
                self.alertPopupMessage = "Employees' available mileage is not enough to deduct.";
                return;
            }

            if (self.checkValidation() != 0) {
                return;
            }

            self.openConfirmPopup('Are you sure you want to deduct points?');
        },
        onInputMileage(event, employee) {
            const input = event.target.value;
            const mileage = input.replace(/[^0-9]/g, '');

            if (mileage==null || mileage=='' || mileage==0) {
                employee.emptyAmount = true;
            } else {
                employee.emptyAmount = false;
            }

            let result = this.convertCommaStringToNumber(mileage);
            employee.mileageVolume = result;
            employee.mileageVolumeFormat = this.numberWithCommas(result);
            this.calTotalAmount();

            if ( Number.isInteger(Number(result)) ) {
                employee.isNotInteger = false;
            } else {
                employee.isNotInteger = true;
            }
        },
        calTotalAmount() {
            const self = this;

            if (self.excelList.length == 0) return (self.totalAmount = 0);
            self.totalAmount = 0;

            self.excelList.map((employee) => {
                if( !employee.mileageVolume ) return;
                const points = Number(employee.mileageVolume);

                self.totalAmount += points;
            });
        },
        checkValidation() {
            const self = this;

            let validationCnt = 0;

            //validation ---------------------------------------------------------------------------------
            //중복 값 제거
            self.excelList = self.excelList.filter((character, idx) => {
                if (character.email == null || '') return;
                return self.excelList.findIndex((item) => item.email == character.email) === idx;
            });

            //mileageVolume 에 정수 외의 값을 입력했을 경우
            self.excelList.map((employee) => {
                const points = Number(employee.mileageVolume);
                if( Number.isNaN(points)
                    || points < 1
                    || !Number.isInteger(points) ){
                    self.openInvalidAmountPopup();
                    validationCnt++;
                }
            });

            if (validationCnt > 0) {
                return validationCnt;
            }

            //아무도 선택하지 않았을 경우
            if (self.excelList.length == 0) {
                self.showAlertPopup = true;
                self.alertPopupMessage = 'Please add employees to transfer points.';
                validationCnt++;
            }

            self.calTotalAmount();
            return validationCnt;
        },
        clickUploadButton(event) {
            const self = this;
            event.preventDefault();

            if (self.uploadFile == null) {
                alert('Please upload a file.');
                return;
            }

            const formProps = new FormData(self.$refs['fileUploadForm']);
            formProps.append('isTransfer', self.transfer);
            const url = self.$api('uri', 'post-transfer-execute-file');

            self.$axios
                .post(url, formProps, {
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                })
                .then((res) => {
                    self.failedList = res.data.data.failedList;
                    if (self.failedList.length > 0) {
                        self.showFailedListPopup = true;
                        return;
                    }

                    self.excelList = res.data.data.excelList;

                    if (self.excelList != null) {
                        self.excelList.forEach((employee) => {
                            employee.isNotInteger = false;

                            if( !employee.mileageVolume){
                                employee.emptyAmount = true;
                                return;
                            }

                            // 엑셀로 문자 입력 시, 해당 금액 0 / 0으로 기입한 경우
                            let mileageNum = Number(employee.mileageVolume);
                            if( Number.isNaN(mileageNum) || mileageNum==0 ) {
                                employee.mileageVolume = null;
                                employee.mileageVolumeFormat = null;
                                employee.emptyAmount = true;
                                return;
                            }

                            // 소수 기입 시, 알림 발생
                            if ( Number.isInteger(mileageNum) ) {
                                employee.isNotInteger = false;
                            } else {
                                employee.isNotInteger = true;
                            }

                            let mileage = employee.mileageVolume;
                            employee.mileageVolume = this.convertCommaStringToNumber(mileage);
                            employee.mileageVolumeFormat = this.numberWithCommas(mileage);


                            if (employee.transactionNote != null) {
                                employee.transactionNote = employee.transactionNote.substring(0, 30);
                            }
                        });
                    }
                    self.calTotalAmount();
                })
                .catch((err) => {
                    alert('Please check fail again. If the value is empty, upload is not possible.');

                    console.error('err : ', err);
                });
        },
        removeFile() {
            const self = this;
            self.uploadFile = null;
        },
        getFailEmailList() {
            if (this.failedList == null || this.failedList.length == 0) {
                return '';
            }

            let emailList = [];
            for (let i = 0; i < this.failedList.length; ++i) {
                emailList.push(this.failedList[i].rowIndex);
            }

            return '*Rows: ' + emailList.join(', ');
        },
        openConfirmPopup(message) {
            this.confirmMessage = message;
            this.showConfirmPopup = true;
        },
        hideConfirmPopup() {
            this.showConfirmPopup = false;
        },
        clickSubmit() {
            this.hideConfirmPopup();

            if (this.transfer) {
                //mileageVolumeFormat 제거
                let transfer = this.excelList.map((emp) => {
                    // eslint-disable-next-line no-unused-vars
                    const { mileageVolumeFormat, isNotNumber, emptyAmount, isNotInteger, ...other } = emp;
                    return other;
                });
                this.$emit('clickTransferButton', transfer, this.emailMessage);
            } else if (this.deduct) {
                //mileageVolumeFormat 제거
                let deduct = this.excelList.map((emp) => {
                    // eslint-disable-next-line no-unused-vars
                    const { mileageVolumeFormat, isNotNumber, emptyAmount, isNotInteger, ...other } = emp;
                    return other;
                });
                this.$emit('clickDeductButton', deduct, this.emailMessage);
            }
        },

        openInvalidAmountPopup(){
            const self = this;
            self.showInvalidAmountPopup = true;
        },
        hideInvalidAmountPopup(){
            const self = this;
            self.showInvalidAmountPopup = false;
        }
    },
};
</script>

<template>
    <div class="mt-5 rounded border w-full max-w-7xl bg-white shadow box-style-border">
        <div class="w-full Class Properties p-10">
            <div class="w-full h-[124px] bg-[#4640DE] rounded-lg text-center">
                <p class="pt-[15px] text-[#fff] text-[16px]">Available Points</p>
                <p class="text-[#fff] text-[48px]">{{ numberWithCommas(availableWalletAmount) }}</p>
            </div>
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[160px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">File Upload</div>
            </div>
        </div>
        <div class="flex justify-between items-center gap-5 flex-wrap p-5">
            <div class="flex justify-between items-center gap-5 grow">
                <!-- template 를 다운받을때 .png 형식으로 받아야함. 다운로드 될때는 xlsx 형식으로 됨  -->
                <a :href="require(`@/assets/file/transferSampleFile.xlsx.png`)" :download="templateFileName">
                    <ElementsButton text="Download Template File" :fitContent="true" :height12="true" />
                </a>
                <form @submit="clickUploadButton" ref="fileUploadForm" class="flex grow">
                    <ElementsInput v-model="uploadFile" class="grow" inputName="multipartFile" inputtype="file" placeholder="Select File" :full="true" />
                    <ElementsButton class="ml-5" text="Upload" :fitContent="true" :height12="true" inputtype="submit" />
                </form>
                <ElementsButton text="Remove" :fitContent="true" :height12="true" :backgroundRed="true" @click-event="removeFile" />
            </div>
        </div>

        <div class="w-full p-10 overflow-auto">
            <div class="min-w-full w-fit">
                <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                    <div class="w-[147px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">
                        <span v-if="transfer">Transfer</span>
                        <span v-if="deduct">Deduct</span> List
                    </div>
                </div>
                <div class="w-full">
                    <div class="text-[14px] text-center mt-[80px]" v-if="excelList.length == 0">+ Add employees to send points</div>
                </div>
                <div class="mt-[80px]" v-if="excelList.length != 0">
                    <template v-for="(employee, index) in excelList" v-bind:key="index">
                        <div class="flex justify-between items-center py-2.5 w-full" :class="{ 'bg-slate-50': index % 2 !== 0 }">
                            <div class="w-20 text-sm font-normal text-center truncate">
                                {{ index + 1 }}
                            </div>
                            <div class="text-base font-bold w-40 px-4 truncate">
                                {{ employee.employeeName }}
                            </div>
                            <div class="w-72 px-4 text-sm font-normal text-center truncate">
                                {{ $validateUtil.emptyValueToDash(employee.departmentName) }} / {{ $validateUtil.emptyValueToDash(employee.jobTitleName) }}
                            </div>
                            <div class="w-60 px-2 text-sm font-normal text-left "
                                :class="employee.isNotInteger || employee.emptyAmount ? 'mt-5':''">
                                <ElementsInput v-model="employee.mileageVolumeFormat" placeholder="0" :full="true" @input="onInputMileage($event, employee)"/>

                                <p v-if="employee.isNotInteger" class="text-sm text-red-600 text-nowrap">
                                    Please use whole numbers only.
                                </p>
                                <p v-if="employee.emptyAmount" class="text-sm text-red-600 text-nowrap">
                                    Please enter the amount.
                                </p>
                            </div>
                            <div class="w-60 px-2 text-sm font-normal text-center truncate">
                                <ElementsInput v-model="employee.transactionNote" placeholder="Remark" :maxlength="30" :full="true" />
                            </div>
                            <button @click="clickDeleteButton(index)" class="w-32 px-4 border rounded border-indigo-400 text-indigo-400 py-1.5 text-center">
                                Delete
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[147px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">
                    Message
                </div>
            </div>
            <textarea
                v-model="emailMessage"
                placeholder="Message"
                class="mt-[50px] p-10 outline-0 w-full h-[114px] text-sm border rounded-lg bg-white transition-all duration-500 shadow shadow-gray-100" />
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[250px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">Total Amount : {{ numberWithCommas(totalAmount) }} Point</div>
            </div>
            <button @click="clickTransferButton" v-if="transfer"
                    class="rounded mt-[50px] w-full text-center h-[62px] leading-[62px] bg-[#4640DE] text-white">
                Transfer
            </button>
            <button @click="clickDeductButton" v-if="deduct"
                    class="rounded mt-[50px] w-full text-center h-[62px] leading-[62px] bg-[#4640DE] text-white">
                Deduct
            </button>
        </div>
        <Teleport to="body">
            <ElementsModal v-model="showFailedListPopup" buttonText="Close" :warn="true" @button-event="showFailedListPopup = false">
                <div class="mt-3 text-center sm:mt-5 w-96">
                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            Invalid or unregistered email found in the template.<br />
                            Please check the rows below and try again.
                        </p>
                        <p class="text-xs whitespace-normal text-gray-500 mt-2">
                            {{ getFailEmailList() }}
                        </p>
                    </div>
                </div>
            </ElementsModal>
            <ConfirmPopup v-model="showAlertPopup" :confirmDescription="alertPopupMessage" buttonText="OK" />
            <AppPopupHeaderless v-model="showConfirmPopup" title="Confirmation" @buttonEvent="clickSubmit">
                {{ confirmMessage }}
            </AppPopupHeaderless>

            <AppPopupHeaderless buttonText="OK"
                                v-model="showInvalidAmountPopup"
                                title="Invalid Point Amount"
                                :titleCenter="false"
                                :viewCancel="false"
                                @buttonEvent="hideInvalidAmountPopup">
                <div class="font-semibold">
                    ⚠️There is an issue with the amount value.<br>
                    Please check the row with error messages.
                </div>
            </AppPopupHeaderless>
        </Teleport>
    </div>
</template>
<style scoped>
.box-style-border {
    border: 1px solid #d1d5db;
}
</style>
