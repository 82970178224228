<script>
export default {
    components: {},
    props: {
        modelValue: [String, Object, Number],
        name: String,
        readonly: Boolean,
        options: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [{ text: null, value: null }],
        },
        placeholder: {
            type: String,
            default: '',
        },
        wFull: Boolean,
        hFull: Boolean,
        width14: Boolean,
        width20: Boolean,
        width24: Boolean,
        width36: Boolean,
        width40: Boolean,
        width52: Boolean,
        width60: Boolean,
        width72: Boolean,
        widthCustom:{type:String, default: ''},
        height10: Boolean,
        height11: Boolean,
        height12: Boolean,
        optionClickEvent: Boolean,
        hasSearchButton: Boolean,
        buttonClassProps : {
            type : String,
            default : 'w-24 h-12 text-[#4261EE]'
        }
    },
    emits: ['change', 'update:modelValue', 'searchEvent'],
    data() {
        return {
            showOptions: false,
            selectText: null,
            inputValue: null,
            selected: null,
        };
    },
    computed: {
        filteredOptions() {
            const self = this;
            if (!self.showOptions) {
                return [];
            }

            return this.options.filter(() => this.inputValue).filter((option) => option.text?.toLowerCase().includes(this.inputValue.toLowerCase()));
        },
    },
    watch: {},
    methods: {
        onInput(event) {
            const self = this;
            self.showOptions = event.target.value == null || event.target.value == '' ? false : true;

            self.inputValue = event.target.value;

            self.$emit('update:modelValue', self.inputValue);
        },
        setInputText(value = null) {
            const self = this;
            self.inputValue = value;

            self.$emit('update:modelValue', self.inputValue);
        },
        selectOption(option) {
            const self = this;
            self.inputValue = option.text;
            self.showOptions = false;

            self.$emit('update:modelValue', self.inputValue);
            if (self.optionClickEvent) {
                self.searchEvent();
            }
        },
        searchEvent() {
            const self = this;
            self.showOptions = false;

            self.$emit('searchEvent');
        },
        getSearchedText() {
            const self = this;
            return self.inputValue;
        },
        getSearchedValues() {
            const self = this;
            if (self.inputValue == null || self.inputValue == '') {
                return null;
            }

            return self.options.filter((option) => option.text.toLowerCase().includes(this.inputValue.toLowerCase())).map((option) => option.value);
        },
    },
    mounted() {},
};
</script>

<template>
    <div class="relative">
        <div class="border border-gray-300 rounded-lg w-full h-full flex justify-start">
            <input
                type="text"
                v-model="inputValue"
                @keyup.enter="searchEvent"
                @input="onInput"
                @focusin="showOptions = true"
                @focusout="showOptions = false"
                class="p-2 text-left cursor-default max-w-6xl border-t-0 border-b-0 border-l-0 border-r-1 border-gray-300 rounded-l-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :class="[{
                    'w-96': !wFull && !width14 && !width20 && !width24 && !width36 && !width40 && !width52 && !width60 && !width72 && !widthCustom,
                    'w-full': wFull,
                    'w-14': width14,
                    'w-20': width20,
                    'w-24': width24,
                    'w-36': width36,
                    'w-40': width40,
                    'w-52': width52,
                    'w-60': width60,
                    'w-72': width72,
                    'h-full': hFull,
                    'h-10': height10,
                    'h-11': height11,
                    'h-12': height12,
                    'bg-gray-100': readonly,
                },
                widthCustom ]"
                :placeholder="placeholder" />
            <button v-if="hasSearchButton" class="" :class="[buttonClassProps]" @click="searchEvent">Search</button>
        </div>
        <ul
            v-if="filteredOptions.length"
            class="border border-t-0 bg-white rounded-md absolute z-10"
            :class="{
                'w-96': !wFull && !width14 && !width20 && !width24 && !width36 && !width40 && !width52 && !width60 && !width72,
                'w-full': wFull,
                'w-14': width14,
                'w-20': width20,
                'w-24': width24,
                'w-36': width36,
                'w-40': width40,
                'w-52': width52,
                'w-60': width60,
                'w-72': width72,
                'bg-gray-100': readonly,
            }">
            <li v-for="option in filteredOptions" :key="option.text" @mousedown="selectOption(option)" class="p-2 hover:bg-gray-200 cursor-pointer">
                {{ option.text }}
            </li>
        </ul>
    </div>
</template>
