import moment from 'moment';

var RecaptchaUtil = {
    setRecaptchaJs: function () {
        var scriptEl = document.createElement('script');
        scriptEl.src = '//www.google.com/recaptcha/api.js';
        document.head.appendChild(scriptEl);
    },
    checkReCaptcha: function () {
        if (typeof grecaptcha === 'undefined') return;

        const reCaptchaKey = window.grecaptcha.getResponse();
        if (reCaptchaKey.length > 0) return reCaptchaKey;
        return;
    },
};

// -------------------------------------------------------------------------------------------------

var ValidateUtil = {
    errMsg: {
        email: {
            empty: '*You must enter an email.',
            form: '*Please enter a valid email address.',
        },
        pwd: {
            empty: '*Password can’t be blank.',
            length: '*Password must be min 8 characters and Max 20 characters.',
        },
        captcha: {
            empty: '*Please confirm the CAPTCHA.',
        },
        confirmPwd: {
            empty: '*Password confirmation can’t be blank.',
            match: '*Password confirmation doesn’t match password.',
        },
        name: {
            empty: '*Name can’t be blank.',
            length: '*Name must be min 2 characters and Max 15 characters.',
        },
        phoneNo: {
            empty: '*Mobile number can’t be blank.',
            length: '*Mobile number must be min 10 numbers and max 12 numbers.',
        },
        agree: {
            signUpCheck: '*Promo T&C and Data Privacy must be accepted.',
        },
    },
    checkIsEmpty(word) {
        return word == null || word.trim() == '';
    },
    // 입력을 안해도 되는 데이터에서 사용
    checkIsEmptyChangeSpace(word) {
        if (word == null || word.trim() == '') {
            word = null;
        }
        return word;
    },
    // 입력을 안해도 되는 데이터(날짜)에서 사용
    checkIsEmptyDeleteDate(word) {
        if (word == null || word.trim() == '') {
            word = null;
        }
        return word;
    },
    // 입력을 안해도 되는 데이터(날짜)에서 사용 null 을 반환
    checkIsEmptyChangeNull(word) {
        if (word == null || word.trim() == '') {
            word = null;
        }
        return word;
    },
    checkIsNumber(word) {
        return /^\d+(\.\d+)?$/.test(word);
    },
    checkIsEmail(word) {
        return /.+@.+\..+/.test(word);
    },
    checkPassword(word) {
        // 현재는 자릿수만 체크
        if (word == null) return false;
        return word.length >= 8 && word.length <= 20;
    },
    checkName(word) {
        if (word == null) return false;
        return word.trim().length >= 2 && word.trim().length <= 100;
    },
    checkNameForSMS(word) {
        if (word == null) return false;
        return word.trim().length >= 2 && word.trim().length <= 7;
    },
    checkPhone(word) {
        if (word == null) return false;
        return word.trim().length >= 10 && word.trim().length <= 12;
    },
    checkZipcode(word) {
        if (this.checkIsEmpty(word)) return;
        return word.trim().length <= 7;
    },
    checkTinNumber(tin) {
        if (!tin) return false;
        if (/[^\d]/.test(tin)) return false;

        return tin.trim().length == 12;
    },
    matchPassword(pwd, cPwd) {
        return pwd == cPwd;
    },
    invalidPhoneNumber(phoneNum) {
        if (phoneNum == null || typeof phoneNum != 'string') {
            return true;
        }

        if (!phoneNum || phoneNum.length != 11) {
            return true;
        }
        return false;
    },
    removeCountryCodePhoneNumber(word) {
        let phoneNum = '';
        if (!word) {
            return null;
        }

        if (word.startsWith('63')) {
            phoneNum = word.substring(2);
        } else {
            phoneNum = word;
        }

        if (phoneNum.length < 7 || phoneNum.length > 20) {
            return null;
        }

        return phoneNum;
    },
    convertPhoneGlobalToLocal(word) {
        // 추후 번호를 다르게 표시해야 할 경우를 대비해서 이 부분에 연관된 페이지를 확인하기 위해 해당 함수 삭제하지 않고 남겨 둠.
        let phoneNum = '';
        if (!word) {
            return null;
        }

        if (word.startsWith('63')) {
            phoneNum = word.substring(2);
        } else {
            phoneNum = word;
        }

        if (phoneNum.length < 7 || phoneNum.length > 20) {
            return null;
        }

        return phoneNum;
    },
    over15Charaters(word) {
        if (!word) return word;

        if (word.length < 16) return word;
        return word.substring(0, 16) + '...';
    },
    over15CharFromFullName(word) {
        if (!word) return word;

        const [first, middle, last] = word.split(' ');
        if (!middle && !last) return ValidateUtil.over15Charaters(first);
        if (!last) return ValidateUtil.over15Charaters([first, middle].join(', '));
        return ValidateUtil.over15Charaters([first, last].join(', '));
    },
    over15CharFromFirstOnly(word) {
        if (!word) return word;
        const [first, middle, last] = word.split(' ');
        return ValidateUtil.over15Charaters(first);
    },
    employeeNameSave(first, middle, last) {
        [first, middle, last] = [first, middle, last].map(this.trimData);

        if (!first || !last) return;
        if (!middle) return [first, last].join(' ');
        return [first, middle, last].join(' ');
    },
    trimData(word) {
        if (!word) return word;
        return word.trim();
    },
    numberWithCommas(number) {
        if(!number) return 0;
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    invalidString(str) {
        return typeof str === 'undefined' || str === null || str === '';
    },
    invalidDecimal(min, max, epsilon, checkValue) {
        let greaterThanMin = checkValue - min > epsilon && Math.abs(checkValue - min) > epsilon;
        let lessThanMax = checkValue - max < epsilon && Math.abs(checkValue - max) > epsilon;
        return !greaterThanMin || !lessThanMax;
    },

    emptyValueToDash(value){
        if(!value) return '-';
        else return value;
    }
};

// ----------------------------------------------------------------------------------
const DefaultImage = {
    imgUrl: '/images/empty_image_rectangle.png',
    imgUrlForMain: '/images/empty_image_square.png',
    replaceByDefault(e) {
        e.target.src = DefaultImage.imgUrl;
    },
    replaceByDefaultForMain(e) {
        e.target.src = DefaultImage.imgUrlForMain;
    },
};
const CssClassUtil = {
    getProfileBackColor(count) {
        const colorArray = ['bg-orange-400', 'bg-green-400', 'bg-rose-400', 'bg-blue-500', 'bg-purple-400'];
        const number = count % colorArray.length;
        return colorArray[number];
    },
};
// -------------------------------------------------------------------------------------------------

const NumberFormatUtil = {
    fitDecimalFormatWithComma(value, fraction = 2) {
        if (value === null) {
            return '';
        }
        if (Number.isNaN(value)) {
            return String(value);
        }
        let regexp = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
        let result = Number.parseFloat(value).toFixed(fraction).toString().replace(regexp, ',');
        return result;
    },
};

const DateFormatUtil = {
    fitDateFormat(value) {
        if (!value) {
            return;
        }
        return moment(value).format('MM/DD/yyyy');
    },
    monthDayFormat(value) {
        if (!value) {
            return;
        }
        return moment(value).format('MM/DD');
    },
    convertToDateTimeForServerFormat(date) {
        if (!date) {
            return;
        }
        return moment(date).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
    },
    convertUTCToLocalFormat(utcDateString, formatString) {
        if (!utcDateString || !formatString) {
            console.error("Invalid input: utcDateString or formatString is missing.");
            return null;
        }
        return moment.utc(utcDateString).local().format(formatString);
    },
    // YYMMDDhhmmss --> MM/DD/YYYY(줄바꿈)HH:mm
    fmtGenTimeToMMddyyyyHHmm(genTime) {
        if( !genTime ) return '-';

        console.log("transfer", genTime)
        const yymmddhhmmss = genTime.substr(0, 12);
        return moment(yymmddhhmmss, 'YYMMDDhhmmss').format('MM/DD/YYYY HH:mm').split(' ').join('<br />');
        // 받는 쪽에서 <div v-html="$dateFormatUtil.fmtGenTimeToMMddyyyyHHmm(genTime)"/> 으로 받아야함
    },

    // 2025-03-14T05:23:36Z --> 03/14/2025 13:23  (필리핀 시간으로 표기)
    convertDateTimeToMMddyyyyHHmm(datetime){
        if( !datetime ){
            return "-";
        }
        const date = new Date(datetime);
        const options = { timeZone: "Asia/Manila" };
        const phtDate = new Date(date.toLocaleString("en-PH", options));

        const month = String(phtDate.getMonth() + 1).padStart(2, '0');
        const day = String(phtDate.getDate()).padStart(2, '0');
        const year = phtDate.getFullYear();

        const hours = String(phtDate.getHours()).padStart(2, '0'); // 24시간제
        const minutes = String(phtDate.getMinutes()).padStart(2, '0');

        let newDatetime = `${month}/${day}/${year} ${hours}:${minutes}`.split(' ').join('<br />');
        return newDatetime;
        // 받는 쪽에서 <div v-html="$dateFormatUtil.fmtGenTimeToMMddyyyyHHmm(genTime)"/> 으로 받아야함
    }
};

const CommonDataFormatUtil = {
    employmentTypeFormat(e) {
        if ('FULL_TIME' == e) {
            return 'Regular';
        } else if ('PROBATION' == e) {
            return 'Probationary';
        } else if ('FIXED_TERM_CONTRACT' == e) {
            return 'Contractual';
        } else if ('PROJECT_BASE' == e) {
            return 'Project Base';
        } else if ('OUTSOURCE' == e) {
            return 'Outsourced';
        } else if ('TEMPORARY' == e) {
            return 'Intern/Part-Time';
        }
        return "";
    },
}
export { RecaptchaUtil, ValidateUtil, DefaultImage, CssClassUtil, NumberFormatUtil, DateFormatUtil, CommonDataFormatUtil };
