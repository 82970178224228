<template>
    <div class="w-full cursor-pointer">
        <div class="flex" @click="afterClick">
            <div class="w-full  bg-white rounded-[8px]"
                :class="[selected ? 'border-[#4261EE]':'border-gray-300', { 'border-[1px]' : useBorder } ]"
            >
                <div class="items-center justify-between">
                    <div class="flex items-center h-13 text-[15px] font-medium p-[15px] pl-[20px] pr-[20px]">
                        <div class="w-[60%] text-left font-semibold" :class="[selected ? 'text-[#4261EE]':'text-[#3D3D3D]']"> {{ buttonText }}</div>
                        <div class="flex-1 text-right" v-if="hasClickEvent">
                            <img class="inline-flex px-1 mb-1" src="@/assets/img/people-2.svg" />
                            {{ optionCount }}
                            <div class="inline-flex px-1 mb-1">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 11L5.59317 6.88384C6.13561 6.39773 6.13561 5.60227 5.59317 5.11616L1 1"
                                        stroke="#797979"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-[#FF0000] font-semibold text-sm" v-if="showErrorMsg">{{ errorText }}</div>
        <div v-else class="sr-only text-sm"> EMPTY </div>
    </div>
</template>
<script>
export default {
    props : {
        hasClickEvent : { type : Boolean , default : false },
        useBorder : Boolean,
        buttonText : String, 
        optionCount : Number,
        showErrorMsg : Boolean,
        selected : Boolean,
        errorText : String,
    },
    emits : [ 'onClickButtonCard'],
    methods : {
        afterClick(){
            const self = this;
            if( ! self.hasClickEvent ) return;
            self.$emit('onClickButtonCard');
        }
    }
}
</script>