<template>
    <MainTabs :tabs="tabs" @click-event="clickTab" />

    <!-- date search -->
    <div v-if="tab!=='POINT_BALANCE'" class="w-full max-w-7xl mt-5">
        <ElementsDateSearchPack v-model:startDate="selectDate.startSearchDate"
                                v-model:endDate="selectDate.endSearchDate"
                                @searchEvent="searchSelectedDate" />
    </div>

    <!-- search options-->
    <div class="w-full max-w-7xl mt-5">
        <div class="flex justify-between items-center gap-5 flex-wrap">
            <div class="flex justify-between items-center gap-5">
                <ElementsSelect v-model="selectDate.departmentSeq"
                                :options="departmentOptions"
                                widthCustom="w-[180px]"
                                placeholder="All Departments"
                                :useDefaultMsg="true"
                                @change="searchEmployeeData" />
                <ElementsSelect v-model="selectDate.jobTitleSeq"
                                :options="jobTitleOptions"
                                widthCustom="w-[180px]"
                                placeholder="All Tiers"
                                :useDefaultMsg="true"
                                @change="searchEmployeeData" />
                <ElementsSelect v-model="selectDate.employmentType"
                                :options="employmentTypeOptions"
                                widthCustom="w-[200px]"
                                :placeholder="'All Contract Type'"
                                :useDefaultMsg="true"
                                :height12="true"
                                @change="searchEmployeeData" />
                <ElementsSearchBar v-model="searchInputStr"
                                   :options="employeeOptions"
                                   :height12="true"
                                   widthCustom="w-[200px]"
                                   :placeholder="employeePlaceholder"
                                   @searchEvent="searchEmployeeData"
                                   ref="employeeSearchBar"
                                   :optionClickEvent="true"
                                   :hasSearchButton="true" />
            </div>

            <div class="flex justify-between items-center gap-5">
                <button @click="clickExcelDownload" class="w-[160px] h-[50px] leading-[38px] text-[#FFFFFF] rounded-lg bg-[#1C64F2]">
                    Download Excel
                </button>
            </div>
            <ElementsNotification v-model="showExcelDisableNoti" notificationMsg="There are no downloadable history" :warning="true" />
        </div>
    </div>
</template>

<script>
import MainTabs from '@/components/main/sections/MainTabs.vue';
import ElementsSelect from '@/components/elements/ElementsSelect.vue';
import ElementsNotification from '@/components/elements/ElementsNotification.vue';
import { contractType } from '@/assets/data/optionData.js';

export default {
    name: "mileage_history",
    components: {
        MainTabs,
        ElementsSelect,
        ElementsNotification
    },
    props: {
        tab: {
            type: String,
            default: 'POINT_BALANCE'
        }
    },
    data() {
        return {
            tabs: [
                {name: 'pointBalance'   , text: 'Point Balance'     , current: this.tab=="POINT_BALANCE" },
                {name: 'transferHistory', text: 'Transfer History'  , current: this.tab=="TRANSFER" },
                {name: 'deductHistory'  , text: 'Deductions History', current: this.tab=='DEDUCT' },
            ],

            searchInputStr: '',
            searchedText: null,
            searchedValues: null,
            employeeOptions: [],
            departmentOptions: [],
            jobTitleOptions: [],
            employmentTypeOptions: contractType,
            employeePlaceholder: 'Search Employees',
            selectDate: {
                limit: 10,
                offset: 0,
                departmentSeq: null,
                jobTitleSeq: null,
                employmentType: null,
                employeeSeqList: null,
                startSearchDate: null,
                endSearchDate: null,
            },

            list: [],
            totalCount: 0,
            currentPage: null,
            indexNo: 0,

            showExcelDisableNoti: false,
        };
    },
    mounted() {
        const self = this;
        console.log("tab", self.tab)

        //default 값을 1주일로 가져옴
        self.getEmployeeOptions();
        self.getDepartmentOptions();
        self.getJobTitleOptions();
    },
    methods: {
        clickTab(name) {
            if (name === 'transferHistory') {
                location.href = '/flex_ben/mileage_history/transfer_history';
            }if (name === 'deductHistory') {
                location.href = '/flex_ben/mileage_history/deduct_history';
            }if (name === 'pointBalance') {
                location.href = '/flex_ben/mileage_history/point_balance';
            }
        },

        getDepartmentOptions() { // Departments
            const self = this;

            const url = self.$api('uri', 'get-department');
            const json_query = JSON.stringify({
                showRootNode: true
            });
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                const options = res.data.data.list.map((dep) => ({
                    text: dep.departmentName,
                    value: dep.departmentSeq,
                }));

                self.departmentOptions.push(...options);
            });
        },

        getJobTitleOptions() { // Tiers
            const self = this;

            const json_query = JSON.stringify({
                limit: -1,
                offset: 0,
            });
            const url = self.$api('uri', 'get-jobTitle');
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                const options = res.data.data.list.map((title) => ({ text: title.jobTitleName, value: title.jobTitleSeq }));
                self.jobTitleOptions.push(...options);
            });
        },

        getEmployeeOptions() {
            const self = this;

            // point balance는 resign한 직원까지 모두 검색할 수 있다.
            let url = self.tab === 'POINT_BALANCE' ? self.$api('uri', 'get-employee-all-with-resigned') : self.$api('uri', 'get-employee-exclude-status-resigned');
            const json_query = JSON.stringify({
                limit: -1,
                offset: 0,
            });

            self.$axios
                .get(url, { params: { json_query } })
                .then((res) => {
                    self.employeeOptions = res.data.data.list.map((emp) =>
                        ({ text: emp.employeeName, value: emp.employeeSeq }));
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },

        searchEmployeeData(offset) { // offset parameter는 parents쪽에서 호출할 때만 사용
            const self = this;

            self.indexNo = offset || 0;
            self.getList(self.selectDate.startSearchDate, self.selectDate.endSearchDate, offset);
            self.currentPage = (offset/self.selectDate.limit+1) || 1 ;
        },

        getList(startDate, endDate, offset = 0) {
            const self = this;

            self.selectDate.startSearchDate = startDate;
            self.selectDate.endSearchDate = endDate;
            if (offset == 0) {
                self.searchedText = self.$refs.employeeSearchBar.getSearchedText();
                self.searchedValues = self.$refs.employeeSearchBar.getSearchedValues();
            } else {
                self.$refs.employeeSearchBar.setInputText(self.searchedText);
            }
            self.selectDate.employeeSeqList = self.searchedValues;

            //-------------------------
            let url;
            if(self.tab ==='TRANSFER'){
                url = self.$api('uri', 'get-transfer-history');
            }else if(self.tab === 'DEDUCT'){
                url = self.$api('uri', 'get-deduct-history');
            }else{ // point balance
                url = self.$api('uri', 'get-point-balance');
            }

            self.selectDate.offset = offset;

            const json_query = JSON.stringify(self.selectDate);
            self.$axios
                .get(url, { params: { json_query } })
                .then((res) => {
                    self.list = res.data.data.list;
                    self.totalCount = res.data.data.count;

                    self.sendList();
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },

        clickExcelDownload() {
            const self = this;

            if (self.list.length == 0) {
                self.showExcelDisableNoti = true;
                return;
            }

            //excel download 이기 때문에 limit 을 -1 로 줌
            const sendData = {
                limit: -1,
                offset: 0,
                departmentSeq: self.selectDate.departmentSeq,
                jobTitleSeq: self.selectDate.jobTitleSeq,
                employmentType: self.selectDate.employmentType,
                employeeSeqList: self.searchedValues,
                ...( self.tab === 'POINT_BALANCE' ? {} :
                        {startSearchDate: self.selectDate.startSearchDate,
                         endSearchDate: self.selectDate.endSearchDate}
                )
            };
            const json_query = JSON.stringify(sendData);

            let url;
            if(self.tab === 'TRANSFER'){
                url = self.$api('uri', 'get-transfer-history-file');
            }else if(self.tab === 'DEDUCT'){
                url = self.$api('uri', 'get-deduct-history-file');
            }else { // point balance excel
                url = self.$api('uri', 'get-point-balance-file');
            }

            self.$axios
                .get(url, { params: { json_query } })
                .then((res) => {
                    const downloadLink = res.data.data.excelFilePath;
                    {
                        const aElem = document.createElement('a');
                        aElem.href = downloadLink;
                        aElem.click();
                    }
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },

        //date format must be YYYYMMDD
        searchSelectedDate() {
            const self = this;
            self.getList(self.selectDate.startSearchDate, self.selectDate.endSearchDate, 0);
        },

        sendList(){
            const self = this;
            if(self.tab === 'TRANSFER'){
                self.$emit("getTransferHist", self.list, self.totalCount, self.currentPage, self.indexNo);
            }else if(self.tab === 'DEDUCT'){
                self.$emit("getDeductHist", self.list, self.totalCount, self.currentPage, self.indexNo);
            }else{ // point history
                self.$emit("getPointList", self.list, self.totalCount, self.currentPage, self.indexNo);
            }
        }
    },
}
</script>

<style scoped>
</style>